//*----------  COMPONENTS  ----------*/
import DashBoard            from '@/components/DashBoard/DashBoard.vue'
import MultipleSlider       from '@/components/multiple/MultipleDatesSlider/MultipleDatesSlider.vue'

import service              from '@/services/soporte'
import getFile              from '../../../../services/getFile'

import Calificacion         from './Charts/Calificacion/Calificacion.vue'
import Cerrado              from './Charts/Cerrados/Cerrados.vue'
import TopTipos             from './Charts/TopTipos/TopTipos.vue'
import TopSucursal          from './Charts/TopSucursal/TopSucursal.vue'
import RegistroSucursal     from './Charts/RegistroSucursal/RegistroSucursal.vue'
import CerradoResponsable   from './Charts/CerradoResponsable/CerradoResponsable.vue'
import TopResponsables      from './Charts/TopResponsables/TopResponsables.vue'

    // ? VUEX
//*----------  CHARTS  ----------*/

import { 
    mapActions,
    mapGetters ,
    mapState
}                     from 'vuex';

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Ticket",
    components : {
        DashBoard,
        MultipleSlider,
        Cerrado,
        Calificacion,
        TopTipos,
        TopSucursal,
        RegistroSucursal,
        CerradoResponsable,
        TopResponsables
    },
    props : {
        dataConcepto: Object
    },
    data : () => ({
        transition : 'scale-transition',
        selected        : {},
        picker : {
            menu   : false,
            date   : currentDate
        },
        // tcktCerrado     : 50,
        // tcktCerrados    : 45,
        // tcktNoResuelto  : 35,
        // tcktPausado     : 52,
        consulted : {
            count   : 0,
            date    : {}
        },
        day       : 0 // almacenamos el día seleccionado en el slider
    }),
    watch : {
        'dataSlider' : function() {
            this.setDays( this.dataSlider )
        },
        'selected' : function() {
            this.readDataDashboard()
        },
    },
    computed : {
        ...mapState("slider", ["dataSlider"]),
        ...mapState("dashboard",["slider","dataRender", "dataSelected"]),
        ...mapGetters("dashboard",{
            tcktCreado      : "getContTicketCreado",
            tcktCerrados    : "getContTicketCerrado",
            tcktNoResuelto  : "getContTicketNoResuelto",
            tcktPausado     : "getContTicketPausado"
        }),
    },
    methods : {
        ...mapActions("dashboard",["setDataSource", "setDays"]),
        async applyFilter() {
            // se valida años, mes y día para corroborar si hay cambios o no
            if( this.selected.date.year == this.consulted.date.year && this.selected.date.months == this.consulted.date.months && this.day == this.dataSlider[1] ) {
                this.$Msg.error('No hay cambios en los meses')
                return;
            }
            this.day    = this.dataSlider[1]; // Asignamos el slider 
            let fecha   = new Date( this.selected.date.year , this.selected.date.months, 0 );
            let last    = fecha.getDate();

            await service.SopR_getDataDashboard( this.getBetweencond( this.selected.date.year , this.selected.date.months - 1, 1, last ) ).then( data => { 
                if( this.$Msg.server(data) ){ 
                    this.setDataSource( this.$JsTable.toObj(data.data.dataAsignacion) )
                    this.consulted.date.year = this.selected.date.year;
                    this.consulted.date.months = this.selected.date.months;
                }
            });
        },
        getBetweencond( year, month, diaInicio, diaFin ) {
            let first = new Date( year, month, diaInicio );
            let last  = new Date( year, month, diaFin );
            return { fecha : [first.toJSON().split('T')[0], last.toJSON().split('T')[0]] }
        },
    },
    created() {
    },
    mounted() {

    }
}
