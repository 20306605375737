//*----------  CHARTS  ----------*/
import {
    DxChart,
    DxSeries
} from 'devextreme-vue/chart';

import {
    mapActions,
    mapGetters,
    mapState
} from 'vuex';

var currentDate = new Date().toISOString().substr(0, 10);
export default {
    name: "Ticket",
    components: {
        DxSeries,
        DxChart
    },
    props: {
        dataConcepto: Object
    },
    data: () => ({

    }),
    watch: {

    },
    computed: {
        ...mapGetters("dashboard", {
            dataCalificacion: "getDataCalificacionUsuario"
        }),
    },
    methods: {
        getPromedio() {
            let sum = 0,
                total = 0;
            this.dataCalificacion.forEach(element => {
                sum += element.stars * element.cont;
                total += element.cont;
            });
            
            return total != 0 ? (sum / total).toFixed(2).toString() : "0";
        }
    },
    created() {},
    mounted() {

    }
}
