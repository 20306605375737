
import {
    mapActions,
    mapState
} from "vuex"

const defaultValues = {    
    configDefault : {
        mandatory   : "",
        column      : ""
    }
}

export default {
    props : {
        test : {
            type : String
        },
        config : {
            type : Object
        },
        small : {
            type: Boolean,
            default : true
        },
    },
    data : function () {
        return {
            selectedChips : this.config != undefined && this.config.hasOwnProperty("multiple") ? [new Date().getMonth()+1] : new Date().getMonth()+1,
            selectedYear  : new Date().getFullYear(),
            years : [
                // 2000,
                // 2001,
                // 2002,
                // 2003,
                // 2004,
                // 2005,
                // 2006,
                // 2007,
                // 2008,
                // 2009,
                // 2010,
                // 2011,
                // 2012,
                // 2013,
                // 2015,
                // 2016,
                // 2017,
                // 2018,   
                // 2019,
                2020,
                2021,
                2022
            ],
            ticksLabels : [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30,31],
            maxDate : 31
        }
    }, 
    computed : {
        ...mapState("slider", ["dataSlider","hoy"]),
        configDefault() {
            return {
                ...defaultValues.configDefault,
                ...this.config
            }
        },
        getMonths(){
            var date   = new Date(),
                dateM  = date.getMonths();                
            return this.selectedYear == date.getFullYear() ? dateM.slice(0,date.getMonth()+1) : dateM
        },
        getCurrentYear(){
            return this.selectedYear
        },
    }, 
    watch : {
        'selectedChips' : function() {
            let hoyAux = (new Date ()).getMonth() + 1 != this.selectedChips ?  (new Date (this.selectedYear, this.selectedChips, 0)).getUTCDate() : this.hoy
            this.maxDate =  (new Date (this.selectedYear, this.selectedChips, 0)).getUTCDate()
            this.setDataSlider( [ 1, hoyAux] );
        },
        'selectedYear' : function() {
            let hoyAux = (new Date ()).getMonth() + 1 != this.selectedChips ?  (new Date (this.selectedYear, this.selectedChips, 0)).getUTCDate() : this.hoy
            this.maxDate =  (new Date (this.selectedYear, this.selectedChips, 0)).getUTCDate()
            this.setDataSlider( [ 1, hoyAux] );
        },
    },
    methods : {
        ...mapActions("slider",["setDataSlider"]),
        /**
         * @description Emite evento a componente padre para obtener elementos seleccionados
         */
        getMaxDate () {

        },
        updateChips(){
            this.$emit("getSelected", {
                months  : this.selectedChips,
                year    : this.selectedYear
            })
        },
        getSelected(e){
            this.selectedYear = e
            this.selectedChips = []
        },
        clear(){
            this.selectedChips = []
        },
        updateSlider( ){
            this.setDataSlider( this.dataSlider );
        },
        selectedAll(){                
            var x = 1
            this.selectedChips = this.getMonths.map(m => x++)
        }
        
    }
}
