//*----------  COMPONENTS  ----------*/
import DashBoard      from '@/components/DashBoard/DashBoard.vue'
//*----------  CHARTS  ----------*/
import DxPieChart, {
    DxSize,
    DxSeries,
    DxLabel,
    DxConnector,
    DxExport
} from 'devextreme-vue/pie-chart';

import { 
    mapGetters,
} from 'vuex';

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Ticket",
    components : {
        DxPieChart,
        DxSize,
        DxSeries,
        DxLabel,
        DxConnector,
        DxExport
    },
    props : {
        dataConcepto: Object
    },
    computed : {
        ...mapGetters("dashboard",{
            areas      : "getDataCerradosNoResuelto"
        }),
    },
    methods : {
        pointClickHandler(e) {
            this.toggleVisibility(e.target);
          },
          legendClickHandler(e) {
            let arg = e.target,
              item = e.component.getAllSeries()[0].getPointsByArg(arg)[0];
      
            this.toggleVisibility(item);
          },
          toggleVisibility(item) {
            item.isVisible() ? item.hide() : item.show();
          }
    },
    created() {
    },
    mounted() {

    }
}
