//*----------  CHARTS  ----------*/
import {
    DxChart,
    DxSeries,
    DxArgumentAxis,
    DxLegend,
    DxLabel
  } from 'devextreme-vue/chart';
//   import DxSelectBox from 'devextreme-vue/select-box';

import { 
    mapActions,
    mapGetters ,
    mapState
}                     from 'vuex';

var currentDate = new Date().toISOString().substr(0,10);
export default {
    name : "Ticket",
    components : {
        DxChart,
        DxSeries,
        DxArgumentAxis,
        DxLegend,
        DxLabel
    },
    props : {
        dataConcepto: Object
    },
    data : () => ({
        
    }),
    computed : {
        ...mapGetters("dashboard",{
            topResponsables : "getDataTopResponsable"
        }),
        
    },
}
